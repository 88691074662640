<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    persistent
    v-model="dialogComputed"
  >
    <v-card :dark="dark">
      <v-toolbar
        color="var(--color-primary)"
        dark
        class="text-uppercase overline font-weight-bold"
      >
        {{ $t('sales.typePersonTitle') }}
      </v-toolbar>
      <v-card-text>
        <div></div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left pa-0">
                </th>
                <th class="text-left">
                </th>
                <th class="text-center">
                  {{ $t("actions.cardinfo.amount") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in personTypeTickets"
                :key="item.id_ticket_person"
              >
                <td class="pa-0"> <v-icon color="var(--color-primary)">mdi-account</v-icon> </td>
                <td>{{ item[$root.getTextlang()] }}</td>
                <td class="white--text text-center">
                  <v-container class="input-cantidad">
                    <v-icon class="button-shop" @click="modifyQuantityTickets(item, -1, index)">
                      mdi-minus
                    </v-icon>
                    <input
                      v-model="item.cantidad"
                      class="MyInputNumber"
                      :class="[{'grey--text text--lighten-2': dark}]"
                      maxlength="3"
                      @input="checkit(item, index)"
                      @blur="verifyEmptyFile(item, index)"
                      type="text"
                      v-mask="'###'"
                    />
                    <v-icon class="button-shop" @click="modifyQuantityTickets(item, 1, index)">
                      mdi-plus
                    </v-icon>
                  </v-container>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="my-5 d-flex flex-row justify-center">
          <div class="text-center" style="font-size: 18px;">
            {{ $t("actions.cardinfo.total") }} <strong>{{ getTotalAvailableTickets() }}</strong>
          </div>&nbsp;&nbsp;
          <v-icon style="color: var(--color-primary); transform: rotate(135deg)">mdi-ticket-outline</v-icon>
        </div>
        <div class="text-right text-uppercase" style="font-size: 18px;">
          Max. <strong>20</strong>&nbsp;
          <v-icon :color="dark ? 'white' : 'black'" style="transform: rotate(135deg)">mdi-ticket-outline</v-icon>
        </div>
      </v-card-text>
      <v-card-actions class="py-4 justify-space-between">
        <v-btn
          dark
          text
          :color="dark ? 'var(--color-primary)' : 'black'"
          @click="$emit('cancelSelecParticipants')"
        >
          {{ showClose ? $t('actions.toClose') : $t('actions.goBack') }}
        </v-btn>
        <v-btn
          dark
          color="var(--color-primary)"
          @click="acceptNewSelection"
        >
          {{ $t('actions.step.confirma1') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "tickesQuantitiesDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    showClose: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      personTypeTickets: []
    }
  },
  computed: {
    dialogComputed: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialogComputed: function (val) {
      if(val) this.personTypeTickets = [...this.$store.state.shopping.stadisticsValue]
    }
  },
  mounted() {
    this.personTypeTickets = [...this.$store.state.shopping.stadisticsValue]
  },
  methods: {
    ...mapGetters({
      getTotalAvailableTickets: 'shopping/getTotalAvailableTickets'
    }),
    modifyQuantityTickets(item, sum, index) {
      let cantidadTemp = parseInt(this.getTotalAvailableTickets()) + sum;
      if(cantidadTemp <= 20 && cantidadTemp >= 0) {
        if( parseInt(item.cantidad) + sum < 0) return
        if( parseInt(item.cantidad) + sum > 20) return
        this.personTypeTickets[index].cantidad = parseInt(this.personTypeTickets[index].cantidad) + sum;
      }
    },
    checkit(val, index) {
      if(parseInt(val.cantidad) > 20) {
        this.personTypeTickets[index].cantidad = 20;
      }
      if(parseInt(val.cantidad) < 0) {
        this.personTypeTickets[index].cantidad = 0;
      }
    },
    verifyEmptyFile(item, index){
      if(item.cantidad.length === 0) {
        this.personTypeTickets[index].cantidad = 0;
        return
      }
      else if(this.getTotalAvailableTickets() > 20){
        this.personTypeTickets[index].cantidad = 0;
        return
      }
      this.personTypeTickets[index].cantidad = parseInt(this.personTypeTickets[index].cantidad)
    },
    acceptNewSelection() {
      if(this.getTotalAvailableTickets() > 20) return this.$root.swalAlert("warning", this.$t('messages.ticketsNumberMustLessHundred'));
      if(this.getTotalAvailableTickets() == 0) return this.$root.swalAlert("warning", this.$t('messages.ticketsNumberMustZero'));

      this.$store.dispatch('shopping/setStadisticsValue', this.personTypeTickets)
      this.dialogComputed = false
    }
  }
}
</script>

<style scoped>
  .MyInputNumber {
    font-size: 15px;
    padding: 3px;
    border: none;
    max-width: 60px;
    text-align: center;
    height: 30px !important;
    margin-top: 0px;
    font-weight: 400;
  }
  .button-shop {
    cursor: pointer;
    color: var(--color-primary);
  }
  .input-cantidad {
    border: .5px solid var(--color-primary);
    border-radius: 25px;
    padding: 5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 130px;
  }
</style>